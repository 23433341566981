import React from 'react';




import './spinner.scss';



const Spinner = () => {

    const size = 35;

    const spinnerStyles = {
        '@keyframes spin': {
          '100%': {
            transform: 'rotate(360deg)'
          }
        },
        wrapper: {
          display: 'inline-block'
        },
        spinner: {
          animation: 'spin 1.5s linear infinite',
          transformOrigin: 'center'
        }
      };



    return (

        <div style={spinnerStyles.wrapper} className="spinner">
     <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        style={spinnerStyles.spinner}
      >
        <style>
          {`
            @keyframes spin {
              100% {
                transform: rotate(360deg);
              }
            }
          `}
        </style>
        <g>
          <circle cx="3" cy="12" r="2" />
          <circle cx="21" cy="12" r="2" />
          <circle cx="12" cy="21" r="2" />
          <circle cx="12" cy="3" r="2" />
          <circle cx="5.64" cy="5.64" r="2" />
          <circle cx="18.36" cy="18.36" r="2" />
          <circle cx="5.64" cy="18.36" r="2" />
          <circle cx="18.36" cy="5.64" r="2" />
        </g>
      </svg>
    </div>
    )


}

export default Spinner;
