import React from 'react';
import { useState, useEffect } from 'react';
import useUser from './../hooks/useUser';
import sanitize from './../util/sanitize-error-messages';

import { Link, useNavigate } from 'react-router-dom';

import './profile.scss';

import GoogleLogo from './../images/google-logo.png';
import Chevron from './../images/chevron.png';

import HomeImage from './../images/home-image.png';

import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, signInWithRedirect, getRedirectResult, GoogleAuthProvider, signOut, sendPasswordResetEmail } from 'firebase/auth';

import { doc, getDoc, setDoc } from 'firebase/firestore';

import { firebaseDB } from "./../firebase";

import { motion } from 'framer-motion';


const Profile = ({ personalityData, setPersonalityData, setModalOpen }) => {

  const [pageStatus, setPageStatus] = useState('login');

  //for sign in
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  //for register
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();
  const auth = getAuth();

  const { user, loading } = useUser();
  const provider = new GoogleAuthProvider();

  const imageWhileHoverAnimation = {
    y: [null, -5, 0],
    transition: { duration: 0.3 },
  };


  const logIn = async () => {
    try {
      await signInWithEmailAndPassword(getAuth(), email, password);
      console.log('Successfully logged in');
      navigate('/');
    } catch (e) {
      let errorMessage = e.message.toString();

      let sanitizedErrorMessage = sanitize(errorMessage);

      setError(sanitizedErrorMessage);
    }
  }

  const createAccount = async () => {

    if (password !== confirmPassword) {
      setError('Password and confirm password do not match');
      return;
    }
    if (password.length < 6) {
      setError('Password must be over 6 characters');
      return;
    }


    createUserWithEmailAndPassword(getAuth(), email, password)
      .then((userCredential) => {
        setInitialData(userCredential.user);
        navigate('/');
      })
      .catch((e) => {

        let errorMessage = e.message.toString();

        let sanitizedErrorMessage = sanitize(errorMessage);

        setError(sanitizedErrorMessage);
      });
  }



  const setInitialData = async (user) => {

    await setDoc(doc(firebaseDB, "users", user.uid), {

      username: user.displayName,
      userID: user.uid,
      email: user.email,
      timeCreated: new Date().toISOString(),
      isVerified: user.emailVerified.toString(),
      isPaid: false,


      personalityData: personalityData

    });

  }


  async function doSignOut() {
    signOut(auth).then(() => {
      console.log("Signed out");
      setPersonalityData(null);
      localStorage.clear();
      navigate('/');
    }).catch((error) => {
      console.log(error);
    });

  }

  async function loginWithGooglePopup() {

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...

        let existingData = false;

        const docRef = doc(firebaseDB, "users", user.uid);
        const docSnap = getDoc(docRef);
        if (docSnap.exists() && docSnap.data().personalityData != null) {
          existingData = true;
        }

        if (existingData = false) {
          setInitialData(user);
        }
        navigate('/');
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;


        setError(errorMessage);

      })
  }


  async function loginWithGoogleRedirect() {

    signInWithRedirect(auth, provider)
      .then(() => {
        navigate('/profile');
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...

        setError(errorMessage);

      })
      ;

  }


  const resetPassword = async () => {

    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        setError('');
        setSuccessMessage('Reset password email sent. ')
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = sanitize(error.message);
        setError(errorMessage);
        // ..
      });

  }



  const renderRegister = () => {
    return (
      <div className="profile-right-container">
        <div className="title">
          Register
        </div>
        <div>
          Creating an account will allow you to save your Big Five results so that you can access them from anywhere.
        </div>

        <div className="input-container">

          <div className="text-input">
            <div className="input-label">
              Email:
            </div>
            <input type="text" id="email" className="text-input-box" value={email} onChange={e => setEmail(e.target.value)}>

            </input>
          </div>
          <div className="small-vertical-spacer" />

          <div className="text-input">
            <div className="input-label">
              Password:
            </div>
            <input type="password" id="email" className="text-input-box" value={password} onChange={e => setPassword(e.target.value)}>

            </input>
          </div>
          <div className="small-vertical-spacer" />

          <div className="text-input">
            <div className="input-label">
              Confirm password:
            </div>
            <input type="password" id="email" className="text-input-box" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}>

            </input>
          </div>
        </div>

        <div className="error-text">
          {error}
        </div>

        <div className="login-button-container">
          <button className="button secondary" onClick={() => { createAccount() }}>
            <div className="button-text">
              Register
            </div>
            <img className="button-icon" src={Chevron} alt="chevron" />
          </button>
        </div>

        <div className="login-button-container">
          <button className="button secondary" onClick={() => { loginWithGooglePopup() }}>
            <img className="button-icon" src={GoogleLogo} width="20" height="20" alt="chevron" />
            <div className="button-text" >
              &nbsp;  Register with Google
            </div>
            <img className="button-icon" src={Chevron} alt="chevron" />
          </button>

        </div>


        <div className="text-link" onClick={() => { setPageStatus('login') }}> Already have an account? Log in here </div>

      </div>
    );
  }


  const renderLogin = () => {

    return (
      <div className="profile-right-container">
        <div className="title">
          Log in
        </div>
        <div>
          Creating an account will allow you to save your Big Five results so that you can access them from anywhere.
        </div>

        <div className="input-container">
          <div className="text-input">
            <div className="input-label">
              Email:
            </div>
            <input type="text" id="email" className="text-input-box" value={email} onChange={e => setEmail(e.target.value)}>

            </input>
          </div>

          <div className="small-vertical-spacer" />


          <div className="text-input">
            <div className="input-label">
              Password:
            </div>
            <input type="password" id="email" className="text-input-box" value={password} onChange={e => setPassword(e.target.value)}>

            </input>
          </div>
        </div>

        <div className="error-text">
          {error}
        </div>

        <div className="login-button-container">
          <button className="button secondary" onClick={() => { logIn() }}>
            <div className="button-text">
              Log in
            </div>
            <img className="button-icon" src={Chevron} alt="chevron" />
          </button>
        </div>

        <div className="login-button-container">
          <button className="button secondary" onClick={() => { loginWithGooglePopup() }}>
            <img className="button-icon" src={GoogleLogo} width="20" height="20" alt="chevron" />
            <div className="button-text" >
              &nbsp;  Log in with Google
            </div>
            <img className="button-icon" src={Chevron} alt="chevron" />
          </button>

        </div>




        <div className="text-link" onClick={() => {
          setPageStatus('forgotpassword');
          setError('');
        }}> Forgot password? </div>

        <div className="text-link" onClick={() => {
          setPageStatus('register');
          setError('');
        }}> Don't have an account? Register here</div>

      </div>
    );


  }

  const renderForgotPassword = () => {

    return (<div className="profile-right-container">
      <div className="title">
        Forgot password
      </div>
      <div>
        Enter your email and you will be sent a link where you can reset your password.
      </div>



      <div className="input-container">
        <div className="text-input">
          <div className="input-label">
            Email:
          </div>
          <input type="text" id="email" className="text-input-box" value={email} onChange={e => setEmail(e.target.value)}>

          </input>
        </div>

      </div>


      <div className="error-text">
        {error}
      </div>

      <div className="error-text" style={{ color: 'white' }}>
        {successMessage}
      </div>

      <div className="login-button-container">
        <button className="button secondary" onClick={() => { resetPassword() }}>
          <div className="button-text">
            Send password reset link
          </div>
          <img className="button-icon" src={Chevron} alt="chevron" />
        </button>
      </div>

      <div className="text-link" onClick={() => {
        setError('');
        setPageStatus('login')
      }}> Return to sign in </div>






    </div>);



  }



  const renderSignedIn = () => {

    return (
      <div className="profile-right-container">
        <div className="title">
          Profile
        </div>
        <div>
          {user.email}
        </div>
        {
          personalityData != null ?
            <Link to='/results'>
              <div className="settings-button">
                View my results
              </div>
            </Link>
            :
            <></>
        }

        {
          personalityData != null ?
            <div className="settings-button" onClick={() => { setModalOpen(true) }}>
              Delete results
            </div>
            :
            <Link to='/pre-test'>
              <div className="settings-button">
                Take test
              </div>
            </Link>
        }

        <div className="settings-button" onClick={() => { doSignOut() }}>
          Sign out
        </div>
      </div>
    );

  }

  const renderProfileRight = () => {

    if (user != null) {
      return renderSignedIn();
    }
    else if (pageStatus == 'login') {
      return renderLogin();
    }

    else if (pageStatus == 'forgotpassword') {
      return renderForgotPassword();
    }
    else {
      return renderRegister();
    }
  }


  return (
    <div className="profile">


      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >

        <div className="profile-body">

          <div className="profile-left">
            <div className="profile-left-container">
              <motion.div
                initial={{  y: -10 }}
                animate={{  y: 0 }}
                whileHover={
                  imageWhileHoverAnimation
                }
              >
                <img className="home-image" src={HomeImage} alt="home-image" />
              </motion.div>
              <div className="subheading login"> Understand your personality </div>
              <div className="title login">The Big Five</div>
            </div>
          </div>

          <div className="profile-right">

            {
              renderProfileRight()
            }

          </div>
        </div>
      </motion.div>

    </div>
  );
}



export default Profile;
