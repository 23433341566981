import React from 'react';
import { useState, useEffect } from 'react';



import { motion, stagger, inView, animate } from 'framer-motion';

import './about.scss';



import FactorIllustration1 from './../images/about-images/factor-ill1.svg';
import FactorIllustration2 from './../images/about-images/factor-ill2.svg';

import OpenIllustration from './../images/about-images/open-ill.svg';
import StructuredIllustration from './../images/about-images/struc-ill.svg';
import ExtraversionIllustration1 from './../images/about-images/ext-ill1.svg';
import ExtraversionIllustration2 from './../images/about-images/ext-ill2.svg';
import AgreeablnessIllustration1 from './../images/about-images/agr-ill1.svg';
import AgreeablenessIllustration2 from './../images/about-images/agr-ill2.svg';
import NeuroticismIllustration from './../images/about-images/neu-ill.svg';





const About = ({ }) => {


  return (
    <div className="about-body">

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >

        <div className="about-section">
          <div className="subheading">
            Learn about The Big Five
          </div>
          <div className="title">
            How is the Big Five measured?
          </div>

          <div className="about-images">

            
            <img className="about-image" src={FactorIllustration1} />
            <img className="about-image" src={FactorIllustration2} />

          </div>



          <div className="about-subsection">


            <div className="about-text">
              <div className="small-title"> Factor analysis:</div>

              The Big Five personality facets are derived from a method known as factor analysis. Factor analysis works by taking large amounts of variables in a dataset and uncovering underlying connections between the data: these are called “facets”. This methodology helps discover connections between data points that may not be obvious by looking only at the data itself, and is used in the fields of market research, education, finance, and psychology.

              <p>
                In the case of the Big Five, academics collected large datasets of descriptions of people’s personality (E.g. “Fun”, “Worried”, “Impatient”). A factor analysis is run on these datasets, uncovering correlations between the adjectives used to describe someone’s personality. For example, someone described as “excitable” or “loud” is likely to also be described as “energetic”. A common uniting factor is derived from this, in this case being “extraversion”.
              </p>
            </div>


          </div>

          <div className="about-text">
            <div className="small-title"> Academic research:</div>


            The Big Five is a well-established method for assessing personality in academia. It has been reproduced in diverse groups of populations from independent researchers. Being reproducible sets it apart from many other methods of assessing personality. The Big Five method is scientifically rigorous and does not sugar coat personality aspects, being honest about the positives and negatives of different personality traits. Having an understanding of these personality aspects can be very beneficial in life for gaining an understanding of what type of person you are and how you interact with others and the world.



          </div>



        </div>


        <div className="about-section">
          <div className="title">
            Openness to experience
          </div>

          <div className="about-subsection">

            <div className="about-image-container">
              <img className="about-image" src={OpenIllustration} />
            </div>
            <div className="about-text">
              Openness to experience can be thought of as the tendency to seek novelty and unorthodoxy. People who are open to experience value originality, creativity, and enjoy new experiences. In contrast, people who are closed to experience prefer to stick to what they know, stay in routines, and talk about practicalities in their life rather than abstract ideas.
            </div>

            <div className="about-text">

              <div className="small-title"> Open to experience:</div>

              A benefit of openness to experience is facilitation of novel ideas. People who are open to experience are innovative and creative. They bounce from idea to idea rapidly and do not thrive in environments where they cannot express this creative capacity. They are over represented in the positions of artists, entrepreneurs, inventors, and academics. Their drive to consider new possibilities and opportunities can lead to incredibly useful discoveries that everyone benefits from. However, creative ideas are a double edged sword. There are a lot of ideas that are useless or even dangerous. Most entrepreneurial endeavors fail, most music artists receive little attention, and most new scientific hypotheses don’t go anywhere. However, a small percentage of them may succeed spectacularly. For this reason openness to experience can be thought of as a high risk / reward strategy.
            </div>
            <div className="about-text">

              <div className="small-title"> Closed to experience:</div>
              Being closed to experience can be thought of as a “conservative” temperament, in the sense that people who are closed to experience do not often venture outside of an established consensus. Closed people are not particularly interested in talking about abstract ideas or concepts. They stick to what they know and may find too much change or novelty overwhelming. For this reason, they are consistent and stable. This can be an advantage in contrast to people who are open to experience, who tend to be restless and can run into trouble with their need to shift things around constantly. There are times where this stability is of benefit, and taking the path that’s already established is the wise move. This strategy may be beneficial where an established routine is working, but may be detrimental in an environment that is in need of shaking up. Closed people are overrepresented in rural areas.
            </div>
          </div>
        </div>


        <div className="about-section">

          <div className="title">
            Structured vs Unstructured
          </div>

          <div className="about-image-container-small">
            <img  className="about-image" src={StructuredIllustration} />
          </div>

          <div className="about-subsection">
            <div className="about-text">
              Structuredness can be thought of as the tendency to sacrifice the present for the future. Structured people are hard workers, tidy, make plans, and are rigid and judgemental. Unstructured people are unordered, spontaneous, live in the moment, and have a hard time sticking to deadlines and plans.
            </div>

            <div className="about-text">

              <div className="small-title"> Structuredness:</div>
              Structured people have what can be thought of as “grit”. They are able  to forgo pleasure or enjoyment in the present to achieve long-term goals. It’s not that structured people necessarily enjoy doing this, it’s that they feel uncomfortable if they don’t. They feel a sense of duty and obligation to others and are prone to guilt if they feel like they’re not pulling their weight. Structured people not only judge themselves, but they judge others as well, and for this reason can be uptight and have difficulties letting go and living in the moment when they need to. They can also be overly rigid in their thinking.
            </div>

            <div className="about-text">

              <div className="small-title"> Unstructuredness:</div>
              Unstructured people on the other hand, live in the moment. They have a hard time sacrificing the present moment for the future, and often have difficulties meeting deadlines and sticking to plans. This tendency can lead to problems in academia and work environments where structure and discipline are valued. Unstructured people are more likely to fall into addictions such as drugs, smoking, and alcohol than structured people. This tendency to live in the moment can also be an advantage in certain areas. For example because they are loose and non-judgemental they can be fun in social environments. At times being spontaneous and unstructured can also lead to finding solutions that structured people won’t find.
            </div>
          </div>


        </div>



        <div className="about-section">

          <div className="title">
            Extraversion vs Introversion
          </div>

          <div className="about-images">

            <img className="about-image" src={ExtraversionIllustration1} />
            <img className="about-image" src={ExtraversionIllustration2} />

          </div>

          <div className="about-subsection">
            <div className="about-text">
              Extraversion can be thought of as the tendency and desire to facilitate attention and energy from others. Extraverted people are highly social, enjoy spending time around others, and are excitable and assertive. Introverted people prefer their own company, avoid attention, and are quiet and non-assertive.
            </div>

            <div className="about-text">
              <div className="small-title"> Extraversion:</div>
              Extraverts are social facilitators. They have large social networks and make friends easily. They thrive in situations where they can be around people constantly, and are over represented in leadership positions. Their excitement is infectious, helping others have fun and get motivated. Extraverts thrive on other people’s attention and interest, and feel demotivated if they’re not getting enough of it. Extraverts enjoy being in environments where they can be around others constantly, and are drawn to careers related to this such as acting, performance, and drama. Interestingly, an extravert does not necessarily have to like the people they are around - this is more correlated with a separate facet ( agreeableness ). People who are highly extraverted can eventually wear others down by being overly dominating, and they may benefit from making an effort to spend time on their own and let others speak before them.

            </div>

            <div className="about-text">
              <div className="small-title"> Introversion:</div>
              Introverts gather energy from being alone. They don’t necessarily dislike socialising, it’s just that too much of it wears them out and they need to spend time on their own to recharge. They tend to be less excitable and let others take the lead in social settings. They prefer 1 on 1 interactions rather than parties or large gatherings. Introverts can at times be overly solitary and not speak up as much as they should. They prefer a down-low, thoughtful approach in social situations rather than a loud, energetic one. Introverts find benefits in solitude, using their time to practice hobbies or endeavors on their own. They are able to use their ability to sit with themselves in cases such as writing a book, spending a long time alone in nature, or crafting furniture.

            </div>
          </div>


        </div>


        <div className="about-section">

          <div className="title">
            Agreeableness
          </div>

          <div className="about-images">

            <img className="about-image" src={AgreeablnessIllustration1} />
            <img className="about-image" src={AgreeablenessIllustration2} />

          </div>

          <div className="about-subsection">
            <div className="about-text">
              Agreeableness can be thought of as the degree to which deep social connections are important to a person. Agreeable people have a naturally caring disposition towards others, are soft hearted, and value reciprocity. They feel others' feelings deeply and are generous. However they can have a difficult time articulating their own needs where necessary, or standing up for themselves. Disagreeable people have little issue with standing up for themselves, and put themselves before others. They don’t mind offending others and are blunt and argumentative.

            </div>

            <div className="about-text">
              <div className="small-title"> Agreeableness:</div>
              Agreeable people are people-oriented. They are over represented in caregiving positions such as teaching, nursing, and elderly assistance. They put others' needs before their own, and are compassionate towards others. Women are on average more agreeable than men. Agreeable people tend to be easy to get along with in social settings, and foster harmony between individuals in group sections. Agreeable people can have a difficult time standing up for themselves and calling out others where necessary, as they have an aversion to conflict. They can also have difficulties looking out for their own interests, allowing others to walk over them.

            </div>
            <div className="about-text">
              <div className="small-title"> Disagreeableness:</div>
              Disagreeable people call out others and do not mind offending people. They tend to have a more cynical view of others, and are less people-oriented than agreeable people. They are great at advocating for themselves and advocating for their own interests. They can have difficulties being liked in social settings as they have a tendency to be abrasive and argumentative. Disagreeable people are over-represented in prison populations. Disagreeable people can use their temperament to their advantage in work environments, and tend to do well in roles where disagreement and conflict is necessary, such as sales. They are also good at calling out bad behaviour and speaking the truth to others with their blunt demeanor.

            </div>
          </div>


        </div>


        <div className="about-section">

          <div className="title">
            Emotional stability
          </div>

          <div className="about-image-container">
            <img className="about-image" src={NeuroticismIllustration} />
          </div>

          <div className="about-subsection">
            <div className="about-text">

              Emotional stability can be thought of as the degree to which an individual is affected by negative events and the degree to which they vary in their mood. Emotionally stable people do not get easily bothered by events, don’t mind being in dangerous or stressful situations, and tend to have a relaxed and calm demeanor. They are not self-critical or shy. Neurotic people feel their emotions deeply, and get stressed, angry, anxious, or depressed more easily than emotionally stable people. They are skeptical and self-critical.

            </div>

            <div className="about-text">
              <div className="small-title"> Emotional stability:</div>
              Emotional stability can be a useful asset in situations where a cool, calm head is necessary. Emotionally stable people are over-represented in leadership positions, where one has to deal with a variety of stressful factors every day. They are also over represented in thrill-seeking endeavours such as skydiving, skating, and bungee jumping. Emotionally stable people don’t sweat things and let negative events in their life roll off them. They may be overly confident and blind to their own weaknesses. They can also miss problems that other people are able to see because of their naturally relaxed demeanor.

            </div>
            <div className="about-text">
              <div className="small-title"> Emotional variance:</div>
              Emotional variance is the tendency to be affected by negative emotional states. These negative emotional states can manifest in different ways in people, such as anxiety, depression, or anger. Emotionally variant people are more likely to suffer from mental health issues than emotionally stable people, and are easily pushed into negative mental states. They tend to be shy and slow to open up to people. Emotionally variant people are also highly self-aware and self-critical. This can be a negative and a positive depending on the situation. For example, self-awareness and self-critique is essential in many areas of life. Good workers and good artists notice flaws in what they make so that they can improve in the future. They can also notice threats and negative possibilities that other people may not have noticed. They stay out of trouble and make safe bets because they don’t want to venture into dangerous situations willingly.


            </div>
          </div>


        </div>

      </motion.div>

    </div>



  );
}



export default About;
