import React from 'react';
import { useState, useEffect } from 'react';

import HomeImage from './../images/home-image.png';
import Icons from './../images/icons.png';
import Results from './../images/award-image.svg';
import Science from './../images/research-image.svg';
import OpeClo from './../images/ope_clo.png';
import ConUnc from './../images/con_unc.png';
import ExtInt from './../images/ext_int.png';
import AgrDis from './../images/agr_dis.png';
import EmoNeu from './../images/emo_neu.png';

import Chevron from './../images/chevron.png';
import ChevronDown from './../images/chevron-down.png';

import Spinner from './../components/spinner';

import { Link, useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";


import { motion, stagger, inView, animate } from 'framer-motion';


const Home = ({ completedTestExists, loading }) => {

  const analytics = getAnalytics();

  const imageWhileHoverAnimation = {
    y: [null, -5, 0],
    transition: { duration: 0.3 },
  };

  return (
    <div className="home">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className="home-section-1">
          <div className="background" >
            <div className="home-body">
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={
                  { opacity: 1, y: 0, transition: { delay: 0.1 } }
                }
              >
                <div className="flex-divider">
                  <div className="home-left">
                    <motion.div whileHover={
                      imageWhileHoverAnimation
                    }>
                      <img className="home-image" src={HomeImage} width="230" height="265" alt="home-image" />
                    </motion.div>

                  </div>
                  <div className="home-right">
                    <div className="subheading">
                      Understand your personality
                    </div>
                    <div className="title">
                      The Big Five
                    </div>
                    <div className="home-text">
                      The Big Five is a method of assessing and understanding personality. Every person differs in a way that can be broken down into 5 fundamental personality traits: openness, structuredness, extraversion, agreeableness, and emotional stability. Understanding these traits can help you recognize why you are the person you are, what your weaknesses and strengths are, and how you relate to others in your life.                </div>

                    {loading == true ?

                      <div className="buttons">
                        <div className="flex-center">
                        <Spinner />
                        </div>
                      </div>


                      :

                      <div className="buttons">

                        <div className="flex-divider">

                          {
                            completedTestExists ?

                              <Link to="/results">
                                <button className="button" onClick={() => {
                                  window.scrollTo(0, 0);
                                }}>
                                  <div className="button-text" >  View your results  </div>
                                  <img className="button-icon" src={Chevron} alt="chevron" />

                                </button>
                              </Link>
                              :
                              <Link to="/pre-test">
                                <button className="button" onClick={() => {
                                  logEvent(analytics, 'Clicked on take test');
                                  window.scrollTo(0, 0);
                                }}>
                                  <div className="button-text" >  Take the test  </div>
                                  <img className="button-icon" src={Chevron} alt="chevron" />
                                </button>
                              </Link>
                          }

                          <div className="buttons-divider" />

                          <button className="button" onClick={() => {
                            window.scrollTo({
                              top: 500,
                              left: 0,
                              behavior: 'smooth'
                            })
                          }}><div className="button-text" >  Learn more </div>
                            <img className="button-icon" src={ChevronDown} alt="chevron" />
                          </button>

                        </div>
                      </div>

                    }


                  </div>
                </div>
              </motion.div>
            </div>

          </div>

        </div>



        <div className="home-section-2" >

          <div className="home-body">
            <div className="subheading"> A better metric</div>
            <div className="title"> What is the Big Five? </div>
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={
                { opacity: 1, y: 0, transition: { delay: 0.2 } }}
            >
              <div className="home-small-segment">
                <div className="flex-divider">
                  <motion.div whileHover={imageWhileHoverAnimation}>
                    <img src={Icons} width="200" height="134" className="home-icons-image" alt="icons" />
                  </motion.div>
                  <div className="home-small-segment-right">
                    <div className="small-title"> The Big Five metric:</div>
                    <div className="home-text">
                      The Big Five is a psychology metric used to determine the most fundamental ways in which people differ in terms of their personality. The big 5 traits are openness, structuredness, extraversion, agreeableness, and emotional stability. For each of these traits, people differ in fundamental ways in their behaviour and outlook. Each of the traits are independent from each other.                 </div>
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={
                { opacity: 1, y: 0, transition: { delay: 0.3 } }}
            >
              <div className="home-small-segment">
                <div className="flex-divider">
                  <motion.div whileHover={imageWhileHoverAnimation}>
                    <img className="image-before home-results-image" width="130" height="110" src={Results} alt="research" />
                  </motion.div>
                  <div className="home-small-segment-left">
                    <div className="small-title"> Honest results:</div>
                    <div className="home-text">
                      The Big Five personality test aims to give people a realistic understanding of their personality without sugarcoating strengths and weaknesses. This is in contrast to some other models of personality which aim to flatter the user by only listing positives of a personality trait and not negatives. Each of the traits of personality come with unique perspectives, blindspots, and ways of understanding the world. Recognizing these factors and how they relate to your life can be extremely beneficial.
                    </div>
                  </div>
                  <motion.div whileHover={imageWhileHoverAnimation}>
                    <img className="image-after home-results-image" src={Results} alt="research" />
                  </motion.div>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={
                { opacity: 1, y: 0, transition: { delay: 0.4 } }}
            >
              <div className="home-small-segment">
                <div className="flex-divider">
                  <motion.div whileHover={imageWhileHoverAnimation}>
                    <img className="home-science-image" width="140" height="110" src={Science} alt="science" />
                  </motion.div>
                  <div className="home-small-segment-right">
                    <div className="small-title"> Scientifically grounded:</div>
                    <div className="home-text">
                      The Big Five personality index is a well-studied field of psychology and has a broad literature of academic work behind it. The metric is backed by quantitative scientific studies that have been performed on large groups of diverse people. This website aims to give accurate information and advice based on these scientifically grounded principles.
                    </div>
                  </div>
                </div>

              </div>
            </motion.div>

          </div>

        </div>



        <div className="home-section-3">

          <div className="home-body">
            <div className="vertical-spacer" />
            <div className="subheading"> How it works: </div>
            <div className="title"> The Big Five categories </div>
            <div className="small-vertical-spacer" />
            <div className="text">The Big Five metric treats each of the 5 facets of personality separately. For each of the traits, everybody will fall somewhere on a spectrum, with most people being somewhere in the middle, and a small number of people being extreme outliers. When you add all of the traits together, you get a wide variety of ways in which people express themselves individually.
            </div>
            <div className="home-small-segment">
              <div className="flex-divider">
                <motion.div whileHover={
                  imageWhileHoverAnimation
                }>
                  <img src={OpeClo} width="120" height="110" alt="open" className="two-icons" />
                </motion.div>
                <div className="home-small-segment-right">
                  <div className="small-title"> Open to experience vs Closed to experience  </div>
                  <div className="text">
                    People who are open to experience are more willing to explore and discuss abstract ideas, as well as to seek out novel experiences. Openness is closely linked to creativity. People who are closed to experience are less willing to explore and discuss abstract concepts, and more likely to fall back on more traditional and established ideas.

                  </div>
                </div>
              </div>
            </div>

            <div className="home-small-segment">
              <div className="flex-divider">
                <motion.div whileHover={
                  imageWhileHoverAnimation
                }>
                  <img src={ConUnc} width="120" height="114" className="image-before two-icons" alt="two-icons" />
                </motion.div>
                <div className="home-small-segment-left">
                  <div className="small-title home-small-image"> Structured vs Unstructured:  </div>
                  <div className="text">
                    Structured ( Or conscientious ) people are likely to be diligent with their tasks and to make plans. They value discipline and orderliness, both in their work and in their life. They are also more likely to be judgemental. Unstructured ( Or unconscientious ) people are more carefree and relaxed, and have a more chaotic and disorganised way of living their life. They may see structured people as uptight and judgemental.
                  </div>
                </div>
                <motion.div whileHover={
                  imageWhileHoverAnimation
                }>
                  <img src={ConUnc} width="120" height="114" className="image-after two-icons" alt="open" />
                </motion.div>
              </div>
            </div>

            <div className="home-small-segment">
              <div className="flex-divider">
                <motion.div whileHover={
                  imageWhileHoverAnimation
                }>
                  <img src={ExtInt} width="120" height="151" className="two-icons" alt="two-icons" />
                </motion.div>
                <div className="home-small-segment-right">
                  <div className="small-title"> Extraversion vs Introversion  </div>
                  <div className="text">
                    People who are extraverted gather energy from being around people, enjoy large gatherings, parties, and tend to be more enthusiastic and talkative than introverted people. People who are introverted gather energy from being by themselves, and prefer one-on-one situations for socialising. They tend to be quieter and less likely to speak first.
                  </div>
                </div>
              </div>
            </div>



            <div className="home-small-segment">
              <div className="flex-divider">
                <motion.div whileHover={
                  imageWhileHoverAnimation
                }>
                  <img src={AgrDis} width="120" height="112" className="image-before two-icons" alt="two-icons" />
                </motion.div>
                <div className="home-small-segment-left">
                  <div className="small-title"> Agreeable vs Disagreeable  </div>
                  <div className="text">
                    People who are agreeable are likely to be warm in nature, seek harmony in group-settings, and aim to please others. They are naturally interested in people, and find meaning in deep connections. People who are disagreeable are less afraid of conflict with others, and look out for themselves more than agreeable people. They are not afraid of competition and are direct about what they want.
                  </div>
                </div>
                <motion.div whileHover={
                  imageWhileHoverAnimation
                }>
                  <img src={AgrDis} width="120" height="112" className="image-after two-icons" alt="agreeable" />
                </motion.div>
              </div>
            </div>

            <div className="home-small-segment">
              <div className="flex-divider">
                <motion.div whileHover={
                  imageWhileHoverAnimation
                }>
                  <img src={EmoNeu} width="120" height="118" className="two-icons" alt="two-icons" />
                </motion.div>
                <div className="home-small-segment-right">
                  <div className="small-title"> Emotionally stable vs Emotionally variant  </div>
                  <div className="text">
                    Emotionally stable people do not get easily bothered by stressful situations, and do not swing wildly in their mood. They are able to handle dangerous environments where there is a lot of pressure on them. Emotionally unstable ( Or neurotic ) people are more easily disturbed, and tend to have more ups and downs in their mood. They are more bothered by events than emotionally stable people and tend to be more sensitive to threats and negative events in their life.
                  </div>
                </div>
              </div>
            </div>
            <div className="home-bottom-button">


              <Link to="/about">
                <button className="button" onClick={() => {

                  window.scrollTo(0, 0);
                }}>
                  <div className="button-text" >  Read more  </div>
                  <img className="button-icon" src={Chevron} alt="chevron" />
                </button>
              </Link>


            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}



export default Home;
