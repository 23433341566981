import React from 'react';
import { useState, useEffect } from 'react';
import TestQuestion from './../components/test-question.js'

import { Link } from 'react-router-dom';

import shortQuestionList from './../components/question-list.js';
import Chevron from './../images/chevron.png';
import ChevronLeft from './../images/chevron-left.png';

import './test.scss';
import './home.scss';
import './pre-test.scss';

import Logo from './../images/logo.png';

import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { firebaseDB } from ".././firebase";
import useUser from './../hooks/useUser';

import { getAnalytics, logEvent } from "firebase/analytics";

import { motion } from 'framer-motion';



const Math = require('mathjs');



const Test = ({ setPersonalityData }) => {

  const analytics = getAnalytics();
  const { user, loading } = useUser();

  const showDevTools = false;

  //big 5 distribution curve variables
  const opeMean = 38.1783;
  const opeStd = 6.7191;
  const conMean = 33.1689;
  const conStd = 7.5800;
  const extMean = 29.3245;
  const extStd = 9.1449;
  const agrMean = 39.5369;
  const agrStd = 7.1578;
  const staMean = 35.0433;
  const staStd = 7.6382;


  //distribution curve cutoff points
  //these were for a -100 to 100 scale but i needed a 0 to 100 scale instead
  //const leftPoint4 = -100;
  //const leftPoint3 = -70;
  //const leftPoint2 = -42;
  //const leftPoint1 = -14;
  //const rightPoint1 = 14;
  //const rightPoint2 = 42;
  //const rightPoint3 = 70;
  //const rightPoint4 = 100;


  //distribution curve cutoff points
  const leftPoint4 = 0;
  const leftPoint3 = 15;
  const leftPoint2 = 29;
  const leftPoint1 = 43;
  const rightPoint1 = 57;
  const rightPoint2 = 71;
  const rightPoint3 = 85;
  const rightPoint4 = 100;

  const [progress, setProgress] = useState(1);
  const [ope, setOpe] = useState(0);
  const [con, setCon] = useState(0);
  const [ext, setExt] = useState(0);
  const [agr, setAgr] = useState(0);
  const [sta, setSta] = useState(0);

  const questionDisplayAmount = 10;

  const [questionDisplayMin, setQuestionDisplayMin] = useState(0);
  const [questionDisplayMax, setQuestionDisplayMax] = useState(questionDisplayAmount);

  let questionList = [];
  const [randomizedQuestionList, setRandomizedQuestionList] = useState([]);
  const [selectedList, setSelectedList] = useState(Array(shortQuestionList.length).fill(2));


  const [opePercentile, setOpePercentile] = useState(0);
  const [conPercentile, setConPercentile] = useState(0);
  const [extPercentile, setExtPercentile] = useState(0);
  const [agrPercentile, setAgrPercentile] = useState(0);
  const [staPercentile, setStaPercentile] = useState(0);


  const [opeText, setOpeText] = useState("");
  const [conText, setConText] = useState("");
  const [extText, setExtText] = useState("");
  const [agrText, setAgrText] = useState("");
  const [staText, setStaText] = useState("");



  //when updating results, iterates through all of the questions from the question list, checks their trait, and if it matches the trait, either adds the score to the trait fields, or negates it depending 
  // on the score 
  const updateResults = () => {

    let o = 0;
    let c = 0;
    let e = 0;
    let a = 0;
    let s = 0;

    for (let i = 0; i < questionList.length; i++) {


      if (questionList[i] !== null) {
        if (questionList[i].props.questionTrait == 'O') {

          //console.log(questionList[i].props.questionTrait);
          //console.log(selectedList[i]);

          if (questionList[i].props.questionScore == 1) {
            o += selectedList[i] + 1;
          }
          else {
            o += mapNumRange(selectedList[i]);
          }
        }
        if (questionList[i].props.questionTrait == 'C') {
          if (questionList[i].props.questionScore == 1) {
            c += selectedList[i] + 1;
          }
          else {
            c += mapNumRange(selectedList[i]);
          }
        }

        if (questionList[i].props.questionTrait == 'E') {
          if (questionList[i].props.questionScore == 1) {
            e += selectedList[i] + 1;
          }
          else {
            e += mapNumRange(selectedList[i]);
          }
        }

        if (questionList[i].props.questionTrait == 'A') {
          if (questionList[i].props.questionScore == 1) {
            a += selectedList[i] + 1;
          }
          else {
            a += mapNumRange(selectedList[i]);
          }
        }

        if (questionList[i].props.questionTrait == 'S') {
          if (questionList[i].props.questionScore == 1) {
            s += selectedList[i] + 1;
          }
          else {
            s += mapNumRange(selectedList[i]);
          }
        }
      }
    }


    setOpe(o);
    setCon(c);
    setExt(e);
    setAgr(a);
    setSta(s);
  }


  // returns the proper scores for negative weighted questions 
  const mapNumRange = (num) => {
    if (num <= 0) {
      return 5;
    }
    else if (num <= 1) {
      return 4;
    }
    else if (num <= 2) {
      return 3;
    }
    else if (num <= 3) {
      return 2;
    }
    else {
      return 1;
    }
  }


  const setSelectedButton = (index, selectedButton) => {
    let newSelected = selectedList.map((item, i) => {
      if (i == index) {
        return selectedButton;
      }
      else {
        return item;
      }
    });
    setSelectedList(newSelected);

    updateResults();

  }


  const addProgress = () => {
    let progressVal = progress;
    progressVal += 1;
    setProgress(progressVal);

    let newQuestionDisplayMin = questionDisplayMin + questionDisplayAmount;
    let newQuestionDisplayMax = questionDisplayMax + questionDisplayAmount;
    setQuestionDisplayMin(newQuestionDisplayMin);
    setQuestionDisplayMax(newQuestionDisplayMax);
  }


  const minusProgress = () => {
    let progressVal = progress;
    progressVal -= 1;
    setProgress(progressVal);

    let newQuestionDisplayMin = questionDisplayMin - questionDisplayAmount;
    let newQuestionDisplayMax = questionDisplayMax - questionDisplayAmount;
    setQuestionDisplayMin(newQuestionDisplayMin);
    setQuestionDisplayMax(newQuestionDisplayMax);
  }

  const calculateResults = async () => {
    const opeZ = (ope - opeMean) / opeStd;
    const conZ = (con - conMean) / conStd;
    const extZ = (ext - extMean) / extStd;
    const agrZ = (agr - agrMean) / agrStd;
    const staZ = (sta - staMean) / staStd;



    // Use the cumulative distribution function (CDF) to get the percentile
    const opePercentile = 0.5 * (1 + Math.erf(opeZ / Math.sqrt(2)));
    const conPercentile = 0.5 * (1 + Math.erf(conZ / Math.sqrt(2)));
    const extPercentile = 0.5 * (1 + Math.erf(extZ / Math.sqrt(2)));
    const agrPercentile = 0.5 * (1 + Math.erf(agrZ / Math.sqrt(2)));
    const staPercentile = 0.5 * (1 + Math.erf(staZ / Math.sqrt(2)));

    //console.log("Openness score = " + opePercentile + "Score is in the " + opePercentile * 100 + "Percentile");
    //console.log("Conciencious score = " + conPercentile + "Score is in the " + conPercentile * 100 + "Percentile");
    //console.log("Extraversion score = " + extPercentile + "Score is in the " + extPercentile * 100 + "Percentile");
    //console.log("Agreeableness score = " + agrPercentile + "Score is in the " + agrPercentile * 100 + "Percentile");
    //console.log("Emotional stability score = " + staPercentile + "Score is in the " + staPercentile * 100 + "Percentile");

    setOpePercentile(opePercentile * 100);
    setConPercentile(conPercentile * 100);
    setExtPercentile(extPercentile * 100);
    setAgrPercentile(agrPercentile * 100);
    setStaPercentile(staPercentile * 100);

    let opeScore = 4;
    let conScore = 4;
    let extScore = 4;
    let agrScore = 4;
    let staScore = 4;


    if (opePercentile * 100 <= leftPoint3) {
      setOpeText("Highly closed to experience");
      opeScore = 1;
    }
    else if (opePercentile * 100 <= leftPoint2) {
      setOpeText("Moderately closed to experience");
      opeScore = 2;
    }
    else if (opePercentile * 100 <= leftPoint1) {
      setOpeText("Somewhat closed to experience");
      opeScore = 3;
    }
    else if (opePercentile * 100 <= rightPoint1) {
      setOpeText("Neither open nor closed to experience");
      opeScore = 4;
    }
    else if (opePercentile * 100 <= rightPoint2) {
      setOpeText("Somewhat open to experience");
      opeScore = 5;
    }
    else if (opePercentile * 100 <= rightPoint3) {
      setOpeText("Moderately open to experience");
      opeScore = 6;
    }
    else {
      setOpeText("Highly open to experience");
      opeScore = 7;
    }


    if (conPercentile * 100 <= leftPoint3) {
      setConText("Highly unconscientious");
      conScore = 1;
    }
    else if (conPercentile * 100 <= leftPoint2) {
      setConText("Moderately unconscientious");
      conScore = 2;
    }
    else if (conPercentile * 100 <= leftPoint1) {
      setConText("Somewhat unconscientious");
      conScore = 3;
    }
    else if (conPercentile * 100 <= rightPoint1) {
      setConText("Neither conscientious nor unconscientious");
      conScore = 4;
    }
    else if (conPercentile * 100 <= rightPoint2) {
      setConText("Somewhat conscientious");
      conScore = 5;
    }
    else if (conPercentile * 100 <= rightPoint3) {
      setConText("Moderately conscientious");
      conScore = 6;
    }
    else {
      setConText("Highly conscientious");
      conScore = 7;
    }

    if (agrPercentile * 100 <= leftPoint3) {
      setAgrText("Highly disagreeable");
      agrScore = 1;
    }
    else if (agrPercentile * 100 <= leftPoint2) {
      setAgrText("Moderately disagreeable");
      agrScore = 2;
    }
    else if (agrPercentile * 100 <= leftPoint1) {
      setAgrText("Somewhat disagreeable");
      agrScore = 3;
    }
    else if (agrPercentile * 100 <= rightPoint1) {
      setAgrText("Neither agreeable nor disagreeable");
      agrScore = 4;
    }
    else if (agrPercentile * 100 <= rightPoint2) {
      setAgrText("Somewhat agreeable");
      agrScore = 5;
    }
    else if (agrPercentile * 100 <= rightPoint3) {
      setAgrText("Moderately agreeable");
      agrScore = 6;
    }
    else {
      setAgrText("Highly agreeable");
      agrScore = 7;
    }




    if (extPercentile * 100 <= leftPoint3) {
      setExtText("Highly introverted");
      extScore = 1;
    }
    else if (extPercentile * 100 <= leftPoint2) {
      setExtText("Moderately introverted");
      extScore = 2;
    }
    else if (extPercentile * 100 <= leftPoint1) {
      setExtText("Somewhat introverted");
      extScore = 3;
    }
    else if (extPercentile * 100 <= rightPoint1) {
      setExtText("Neither introverted nor extraverted");
      extScore = 4;
    }
    else if (extPercentile * 100 <= rightPoint2) {
      setExtText("Somewhat extraverted");
      extScore = 5;
    }
    else if (extPercentile * 100 <= rightPoint3) {
      setExtText("Moderately extraverted");
      extScore = 6;
    }
    else {
      setExtText("Highly extraverted");
      extScore = 7;
    }

    if (staPercentile * 100 <= leftPoint3) {
      setStaText("Highly neurotic");
      staScore = 1;
    }
    else if (staPercentile * 100 <= leftPoint2) {
      setStaText("Moderately neurotic");
      staScore = 2;
    }
    else if (staPercentile * 100 <= leftPoint1) {
      setStaText("Somewhat neurotic");
      staScore = 3;
    }
    else if (staPercentile * 100 <= rightPoint1) {
      setStaText("Neither neurotic nor emotionally stable");
      staScore = 4;
    }
    else if (staPercentile * 100 <= rightPoint2) {
      setStaText("Somewhat emotionally stable");
      staScore = 5;
    }
    else if (staPercentile * 100 <= rightPoint3) {
      setStaText("Moderately emotionally stable");
      staScore = 6;
    }
    else {
      setStaText("Highly emotionally stable");
      staScore = 7;
    }

    if (user != null) {

      
      const docRef = doc(firebaseDB, "users", user.uid);

      console.log("docRef" + docRef );

      if (docRef != null) {

        await updateDoc(docRef, {
          personalityData: { "ope": parseInt(opeScore), "con": parseInt(conScore), "ext": parseInt(extScore), "agr": parseInt(agrScore), "sta": parseInt(staScore) }
        });

      }
        

      console.log("Successfully submitted data");
    }
    else {
      localStorage.setItem("personalityData", (JSON.stringify({ "ope": opeScore, "con": conScore, "ext": extScore, "agr": agrScore, "sta": staScore })));
    }

    setPersonalityData({ "ope": opeScore, "con": conScore, "ext": extScore, "agr": agrScore, "sta": staScore });

  }



  // Effect to shuffle the array once during component initialization
  useEffect(() => {
    setRandomizedQuestionList(shuffle(shortQuestionList));
    updateResults();
  }, []);


  shortQuestionList.forEach((item, index) => {

    questionList.push(<TestQuestion index={index}
      selected={selectedList[index]}
      setSelected={setSelectedButton}
      key={index}
      questionText={shortQuestionList[index].question}
      questionTrait={shortQuestionList[index].trait}
      questionScore={shortQuestionList[index].score} />);

  });


  const shuffle = (array) => {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }


  return (
    <div className="test">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className="heading-section">
          <div className="flex-divider">
            <img className="bigfive-logo" src={Logo} alt="logo" width="297" height="363" />
            <div className="heading-section-text">
              <div className="heading-section-title"> Questions </div>
              <div className="heading-section-subtitle"> Read the following statements and respond with how much you agree with them </div>
            </div>
          </div>
        </div>

        {renderDevTools(showDevTools, ope, con, ext, agr, sta, opePercentile, conPercentile, extPercentile, agrPercentile, staPercentile, opeText, conText, extText, staText, agrText, calculateResults
        )}

        <div className="test-questions">
          <div className="test-top"> Part {progress} of 5</div>
          {questionList.slice(questionDisplayMin, questionDisplayMax)}

          <div className="test-bottom">

            {progress > 1 ?
              <button className="button secondary" onClick={() => {
                minusProgress();
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              }}>
                <img className="button-icon" src={ChevronLeft} alt="chevron" />
                <div className="button-text">
                  Previous
                </div>

              </button>
              : //invisible for styling reasons
              <button style={{ visibility: 'hidden' }} className="button secondary">
                <div className="button-text">
                  Previous
                </div>
                <img className="button-icon" src={ChevronLeft} alt="chevron" />
              </button>
            }

            <div className="test-bottom-text"> Part {progress} of 5</div>

            {progress < 5 ?
              <button
                className="button secondary"
                onClick={() => {

                  logEvent(analytics, 'Finished test');


                  addProgress();
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }}>
                <div className="button-text">
                  Next
                </div>
                <img className="button-icon" src={Chevron} alt="chevron" />
              </button>

              : <Link to="/results"><button onClick={() => {
                calculateResults();
                window.scrollTo(0, 0);
              }} className="button secondary" >
                <div className="button-text">
                  Finish
                </div>
                <img className="button-icon" src={Chevron} alt="chevron" />
              </button> </Link>
            }

          </div>
        </div>
      </motion.div>
    </div>
  );
}


const renderDevTools = (showDevTools, ope, con, ext, agr, sta, opePercentile, conPercentile, extPercentile, agrPercentile, staPercentile, opeText, conText, extText, staText, agrText, calculateResults) => {

  if (showDevTools == true) {

    return (
      <>
        <div className="center"> O: {ope} C:{con} E:{ext} A:{agr} S:{sta} </div>
        <div className="center"> Results: Openness percentile: {opePercentile} Concienciousness percentile: {conPercentile}
          Extraversion percentile: {extPercentile} Agreeableness percentile {agrPercentile} Emotional stability percentile: {staPercentile} </div>

        <div className="center"> {opeText},{conText},{extText},{staText},{agrText}  </div>


        <h3><button onClick={calculateResults}> update ( for testing ) </button></h3>
      </>
    )
  }
  else {
    return (<> </>);
  }



}

export default Test;
