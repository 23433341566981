import React from 'react';



import './result-image.scss';


const ResultImage = ({ image, text }) => {


    return (
        <div class="result-image">
             <div className = "facet-image-circle">
            <img className="facet-image" src={image} alt="logo" />
            </div>
            <div className = "facet-image-text">
                {text}
            </div>
        </div>
    )
}


export default ResultImage;
